td, th {
	padding: 0.5em;
}

table.sites, table.upgrades {
	width: 100%;
	
	td, th {
		text-align: center;
		&.name, &.upgraded {
			text-align: left;
		}
		
	}
}

.update-formatter {
	textarea {width: 100%; height: 300px;}
}

button.clear-state {
	position: absolute;
	right: 30px;
}

body.dragging, body.dragging * {
  cursor: move !important;
}

.dragged {
  position: absolute;
  opacity: 0.5;
  z-index: 2000;
}

table tr.placeholder::before {
	content: "";
	position: absolute;
	width: 0;
	height: 0;
	border: 5px solid transparent;
	border-left-color: red;
	margin-top: -5px;
	left: -5px;
	border-right: none;
}

table tr.placeholder {
	display: block;
	background: red;
	position: relative;
	margin: 0;
	padding: 0;
	border: none;
}

.panel-body .sites .expired {
	color: #c00;
}
.panel-body .sites .inactive, .panel-body .sites .inactive a {
	color: #bbb;
}