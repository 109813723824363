
// Fonts
@font-face {font-family: 'AvenirLTPro-Light';src: url(https://thewpservice.com/wp-content/themes/thewpservice/webfonts/2EF4FD_0_0.woff2);}

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "styles";